<template>
  <div id="chart-bar-area-publish-box">
    <div class="chart-title">
      <img class="charts-icon" :src="require('@/assets/images/largescreen/charts-icon.png')" alt="">
      <dv-decoration-1 />
      <span style="font-size: 12px;">{{ areaName === 'china' ? '全国' : areaName }}</span>
    </div>

    <div id="bottom-box" class="bottom-box">

      <!--部署学校数-->
      <!--参与教师数-->
      <!--覆盖学生数-->
      <!--累计课时-->


      <div data-v-0234f859="" class="item_title_content">
        <ul data-v-2517bd62="" data-v-2b0723e4="" class="user_Overview flex">
          <li data-v-2517bd62="" class="user_Overview-item" style="color: rgb(0, 253, 250);">
            <div data-v-2517bd62="" class="user_Overview_nums allnum">
              <div data-v-2517bd62="" class="dv-digital-flop" style="width: 100%; height: 100%;">{{
                this.mapJsonData.publishView[0] }}</div>
            </div>
            <p data-v-2517bd62="">{{ this.infoOpenPubSubType['schoolCount'] }}</p>
          </li>
          <li data-v-2517bd62="" class="user_Overview-item" style="color: rgb(7, 247, 168);">
            <div data-v-2517bd62="" class="user_Overview_nums online">
              <div data-v-2517bd62="" class="dv-digital-flop" style="width: 100%; height: 100%;">{{
                this.mapJsonData.publishView[1] }}</div>
            </div>
            <p data-v-2517bd62="">参与教师数</p>
          </li>
          <li data-v-2517bd62="" class="user_Overview-item" style="color: rgb(227, 179, 55);">
            <div data-v-2517bd62="" class="user_Overview_nums offline">
              <div data-v-2517bd62="" class="dv-digital-flop" style="width: 100%; height: 100%;">{{
                this.mapJsonData.publishView[2] }}</div>
            </div>
            <p data-v-2517bd62="">覆盖学生数</p>
          </li>
          <li data-v-2517bd62="" class="user_Overview-item" style="color: rgb(245, 2, 61);">
            <div data-v-2517bd62="" class="user_Overview_nums laramnum">
              <div data-v-2517bd62="" class="dv-digital-flop" style="width: 100%; height: 100%;">{{
                this.mapJsonData.publishView[3] }}</div>
            </div>
            <p data-v-2517bd62="">累计课时</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import resize from '@/utils/resize'

export default {
  mixins: [resize],
  name: "bodyLeftTop",
  data() {
    return {
      chart: null,
      infoOpenPubSubType: {
        'schoolCount': '部署学校数',
        'teacherCount': '参与教师数',
        'studentCount': '覆盖学生数',
        'chapterCount': '累计课时',
      },
      mapJsonData: {
        pubSubName: [],
        pubSubType: [],
        publishView: [],
        publishViewZero: [],
      }
    }
  },
  props: {
    areaName: {
      type: String,
      default() {
        return ''
      }
    },
    areaLevel: {
      type: Number,
      default() {
        return 0
      }
    },
    daTjInformation: {
      type:Array,
      default(){
        return []
      }
    },
    columName: {
      type: String,
      default() {
        return '1'
      }
    }
  },
  created() {
  },
  mounted() {

  },
  watch: {
    daTjInformation: {
      handler(val) {
        this.initChart()
      }
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      if (this.columName == '3') {
        this.infoOpenPubSubType['schoolCount'] = '年级数'
      }
       var first = this.daTjInformation[0]
      this.mapJsonData = {
        pubSubName: [],
        pubSubType: [],
        publishView: [],
        publishViewZero: [],
      }

        this.mapJsonData.pubSubName.push(this.infoOpenPubSubType['schoolCount'])
        this.mapJsonData.pubSubType.push('schoolCount')
        this.mapJsonData.publishView.push(this.daTjInformation.length)
        this.mapJsonData.publishViewZero.push(0)

      for (var key in this.infoOpenPubSubType) {
        if(key==='schoolCount'){continue}
        this.mapJsonData.pubSubName.push(this.infoOpenPubSubType[key])
        this.mapJsonData.pubSubType.push(key)
        this.mapJsonData.publishViewZero.push(0)
        var total=0
        for(var data in this.daTjInformation){
          total= total + this.daTjInformation[data][key]
        }
        this.mapJsonData.publishView.push(total)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.lr_titles .item_title_content {
  height: calc(100% - 38px)
}

#chart-bar-area-publish-box {
  background: rgba(125, 155, 243, .2);
  height: 50%;

  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    background: rgba(125, 155, 243, 0.2);
    position: relative;

    .charts-icon {
      width: 20px;
      height: 25px;
      position: absolute;
      left: 15px;
      top: 0;
    }

    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }

  .bottom-box {
    background-size: 100% 100%;
    box-sizing: border-box;
    height: calc(100% - 28px);
    .item_title_content{
      height:100%;
      .user_Overview{
        flex-wrap: wrap;
        padding-top: 10px;
        height:100%;
      }
    }
  }

  #chart-bar-area-publish {
    height: calc(100% - 30px);
  }
}


.user_Overview li[data-v-2517bd62] {
  flex: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user_Overview li p[data-v-2517bd62] {
  text-align: center;
  height: 16px;
  font-size: 0.8rem;
  margin-top:5px;
}

.user_Overview li .user_Overview_nums[data-v-2517bd62] {
  min-width: 90px;
  min-height: 90px;
  text-align: center;
  line-height: 90px;
  background-size: cover;
  background-position: 50%;
  position: relative
}

.user_Overview li .user_Overview_nums[data-v-2517bd62]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.user_Overview li .user_Overview_nums.bgdonghua[data-v-2517bd62]:before {
  -webkit-animation: rotating 14s linear infinite;
  animation: rotating 14s linear infinite
}

.user_Overview li .allnum[data-v-2517bd62]:before {
  background-image: url(../assets/images/largescreen/one.png);
  background-size: 100% 100%;
}

.user_Overview li .online[data-v-2517bd62]:before {
  background-image: url(../assets/images/largescreen/two.png);
  background-size: 100% 100%;
}

.user_Overview li .offline[data-v-2517bd62]:before {
  background-image: url(../assets/images/largescreen/three.png);
  background-size: 100% 100%;
}

.user_Overview li .laramnum[data-v-2517bd62]:before {
  background-image: url(../assets/images/largescreen/four.png);
  background-size: 100% 100%;
}

.flex {
  display: flex
}

.bottom-row {
  height: 50%;
}

.bottom-row ul {
  height: 100%;
}

.bottom-row li {
  float: left;
  height: 100%;
  width: 50%;
}

.bottom-row li.big {
  width: 30%;
  padding-left: 70px;
  position: relative;
}

.bottom-row li.big2 {
  width: 26%;
}
</style>