<template>
  <div id="chart-bar-area-publish-box">
    <div class="chart-title">
      <img class="charts-icon" :src="require('@/assets/images/largescreen/charts-icon.png')" alt="">
      <dv-decoration-1 />
      <span style="font-size: 12px;">已开设心育课程类型占比</span>
    </div>
    <div id="chart-bar-area-publish2" />
  </div>
</template>
<script>
import resize from '@/utils/resize'

export default {
  mixins: [resize],
  name: "HomeView",
  components: {
  },
  data() {
    return {
      chartPie: null,
      piedata: [],
      seriesdata: []
    }
  },
  props: {
    courseTypeCount: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    courseTypeCount: {
      handler(val) {
        this.drawCharts()
      },
    }
  },
  methods: {
    drawPieChart() {
      this.chartPie = this.$echarts.init(document.getElementById('chart-bar-area-publish2'));
      this.chartPie.setOption({
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        series: [
          {
            name: '课程分类占比',
            // type: 'pie',
            // radius: '55%',
            //center: ['50%', '50%'],//饼图的位置

            type: 'pie',
            roseType: 'radius',
            radius: ['20%', '55%'],
            center: ['50%', '50%'],

            label: {            //饼图图形上的文本标签
              normal: {
                show: true,
                textStyle: {
                  fontSize: 12,//文字的字体大小                  
                },
              }
            },
            data: this.seriesdata,
            color: [
              "#FFBA1F",
              "#30C9C9",
              "#FF2934",
              "#A878E8",
              "#9F9BFF",
              "#FF6465",
              "#36F2F2",
              "#FC38A4",
              "#F87930",
              "#59FD29",
              "#5FD879",
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
                normal: {
                    show: true,
                    length: 8,
                    length2: 6,
                }
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
          }
        ]
      });
      window.addEventListener("resize", () => {
        if (this.chartPie) {
          this.chartPie.resize()
        }
      })
    },
    drawCharts() {
      this.seriesdata = []
      this.courseTypeCount.forEach((item, index) => {
        //this.seriesdata.push({ value: item.total, name: item.courseName })
        this.seriesdata.push({ value: item.typeNum, name: item.typeName })
      })
      this.drawPieChart()
    },
  }
};
</script>

<style lang="scss" scoped>
#chart-bar-area-publish-box {
  background: rgba(125, 155, 243, .2);
  height: 50%;

  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    background: rgba(125, 155, 243, .2);
    position: relative;

    .charts-icon {
      width: 20px;
      height: 25px;
      position: absolute;
      left: 15px;
      top: 0;
    }

    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }

  #chart-bar-area-publish2 {
    height: calc(100% - 30px);
  }
}
</style>