<template>
  <div id="largescreen" :style="_isMobile() ? {} : largescreenPcStyle">
    <el-row :gutter="10">
      <el-col :lg="24" :md="24" :sm="24" :xs="24">
        <top-header/>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="padding: 10px;height: calc(100% - 60px);">
      <el-col :lg="6" :md="6" :sm="24" :xs="24">
        <body-left-bottom 
        :area-name="'心理课程应用情况 - '+(areaName==='china'?'全国':areaName)"
        :da-tj-information="daTjInformation"
        :columName="this.users.userRole"/>
        <body-left-top :gradeList="gradeList"/>
        <!-- <body-left-center :today-pv-number="todayPvNumber"/> -->
      </el-col>
      <el-col :lg="12" :md="12" :sm="24" :xs="24">
        <div class="map-item-amounts">
         <span style="font-size:12px;">当前部署学校数： {{ amounts }} </span> 
        </div>
        <china
            :area-code.sync="areaCode"
            :area-level.sync="areaLevel"
            :area-name.sync="areaName"
            :map-name-list.sync="mapNameList"
            :map-code-list.sync="mapCodeList"
            :area-statistic="areaStatistic"
            :flag="flag"
            :area-school="areaSchool"
        />
        <body-center-bottom
            :area-name="areaName"
            :schoolInfoList="schoolInfoList"
            :columName = "this.users.userRole"
        />
      </el-col>
      <el-col :lg="6" :md="6" :sm="24" :xs="24">
        <!-- <body-right-bottom :timed-complain="timedComplain"/> -->
        <body-left-center :today-pv-number="todayPvNumber"/>
        <!-- <body-right-center :satisfied="satisfied"/> -->
        <body-right-top :courseTypeCount="courseTypeCount"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import China from "../components/china";
import TopHeader from "../components/topHeader.vue";
import BodyLeftTop from "../components/bodyLeftTop.vue";
import BodyLeftCenter from "../components/bodyLeftCenter.vue";
import BodyLeftBottom from "../components/bodyLeftBottom.vue";
import BodyRightTop from "../components/bodyRightTop.vue";
import BodyRightTop1 from "../components/bodyRightTop1.vue";
import BodyRightCenter from "../components/bodyRightCenter.vue";
import BodyRightBottom from "../components/bodyRightBottom.vue";
import BodyCenterBottom from "../components/bodyCenterBottom.vue";
import {
  getCurrentLevelName,
  getSchoolCount,
  getCurrSchoolInfo,
  getCurrCountInfo,
  //getClassCount,  
  getClassCountNew,
  getCourseJoin,
  //getCourseTypeCount
  getCourseTypeCountNew
} from '@/api/largescreen'
import { getInfo } from '@/api/login'

export default {
  name: "index",
  components: {
    BodyCenterBottom,
    TopHeader,
    China,
    BodyLeftTop,
    BodyRightCenter,
    BodyLeftCenter,
    BodyLeftBottom,
    BodyRightTop,
    BodyRightTop1,
    BodyRightBottom,
  },
  data() {
    return {
      users: {},//用户信息
      flag:false,
      areaSchool:[],
      schoolInfoList:[],
      areaCode: '', // 当前用的areaCode
      areaLevel: -1, // 当前用的areaCode
      areaName: '', // 当前用的areaName
      mapNameList: [], // 当前地图上的地区名字
      mapCodeList: [], // 当前地图上的地区Code
      largescreenPcStyle: {
        position: 'fixed',
        top: 0,
        left: 0,
      },
      queryParams: {},
      areaStatistic: [], // 测试
      amounts: 0, // 测试
      gradeList: [], // 测试
      satisfied: {}, // 测试
      timedComplain: [], // 测试
      todayPvNumber: [], // 测试
      daTjInformation: [], // 左侧状图
      courseTypeCount:[], //课程分类占比
      daTjItemsInfo: [], // 测试
      complaintOverview: {}, // 测试
      pageview: {}, // 测试
      totalPublish: 0, // 测试
      totalItems: 0, // 测试
      thirdPlatform: 0, // 测试
      dateRange: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  created() {
    this.userInfo()
    this.initData()
    // this.setDateRange() //设置时间段
  },
  watch: {
    areaLevel: {
      handler(val) {
        this.flag=false
        this.requestGetSumComplaint()//分区学校数据
        this.requestGetSumPvNumber()//学校资源数表格
        this.requestGetComplainStatistic()//学校资源数柱状图
        //this.requestGetSatisfied()//开通年级占比
        this.requestGetSatisfiedNew()//开通年级占比
        this.requestGetTimedComplain() //课程参与率
        //this.requestGetDaTjInformation() //课程分类占比
        this.requestGetDaTjInformationNew() //课程分类占比
      },
      deep: true
    }
  },
  methods: {   
     //用户信息
    userInfo() {
            var token = localStorage.getItem("token");
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
    initData(){
      getCurrentLevelName().then(res=>{
        this.areaLevel = res.data.areaLevel
        this.areaCode = res.data.adcode
        this.areaName = res.data.areaName
      });
    },
    // 设置默认时间段， 一周
    setDateRange() {
      this.dateRange[0] = this._parseTime(new Date().getTime() - 60 * 60 * 24 * 7 * 1000, '{y}-{m}-{d}')
      this.dateRange[1] = this._parseTime(new Date().getTime() - 60 * 60 * 24 * 1000, '{y}-{m}-{d}')
    },
    // 时间change
    onDateRangeChange(val) {
      this.getData()
    },
    getData() {
      this.requestGetSumComplaint() // 获取当前学校数据
      this.requestGetSumPvNumber() // 获取学校列表
      this.requestGetComplainStatistic() // 资源信息柱状图
      //this.requestGetSatisfied() // 开通年级占比
      this.requestGetSatisfiedNew() // 开通年级占比
      this.requestGetTimedComplain() // 课程参与率
      //this.requestGetDaTjInformation() // 课程分类占比
      this.requestGetDaTjInformationNew() // 课程分类占比
      this.requestGetComplaintOverview() // 测试
      this.requestGetPageview() // 测试
      this.requestGetTotalPublish() // 测试
      this.requestGetTotalItems() // 测试
      this.requestGetThirdPlatform() // 测试
    },
    // 设置请求参数
    setParam() {
      var obj = {
        areaCode: this.areaCode,
        areaLevel: this.areaLevel,
        areaName: this.areaName,
        schoolList:this.schoolList
      }
      if (this.dateRange.length > 0) {
        obj.beginDate = this.dateRange[0]
        obj.endDate = this.dateRange[1]
      }
      return obj
    },
    // 设置请求参数无时间
    setParamDate() {
      var obj = {}
      if (this.dateRange.length > 0) {
        obj.beginDate = this.dateRange[0]
        obj.endDate = this.dateRange[1]
      }
      return obj
    },
    // 设置请求参数无时间
    setParamNoDate() {
      var obj = {
        areaCode: this.areaCode,
        areaLevel: this.areaLevel,
      }
      return obj
    },
    // 获取学校数量、层级
    requestGetSumComplaint() {
        getSchoolCount(this.areaLevel,this.areaCode).then(r=>{
          this.areaSchool = r.data.schoolList
          this.amounts = r.data.schoolCount
          var param = this.setParam()
          this.flag=true
      })
    },
    // 获取底部学校信息
    requestGetSumPvNumber() {
      getCurrSchoolInfo(this.areaCode,this.areaLevel).then(res=>{
        this.schoolInfoList=res.data
      })
    },
    // 时间比较
    compareTime(prop, align) {
      return function (a, b) {
        var val1 = a[prop]
        var val2 = b[prop]
        if (align) {
          return new Date(val2) - new Date(val1)
        }
        return new Date(val1) - new Date(val2)
      }
    },
    // 学校资源柱状图
    requestGetComplainStatistic() {
      getCurrCountInfo(this.areaLevel,this.areaCode).then(res=>{
        this.daTjInformation = res.data
      });
    },
    // 开通年级占比饼图
    requestGetSatisfied() {
      getClassCount(this.areaLevel,this.areaCode).then(res=>{
        this.gradeList= res.data
      })
    },
    // 开通年级占比饼图
    requestGetSatisfiedNew() {
      getClassCountNew(this.areaLevel,this.areaCode).then(res=>{
        this.gradeList= res.data
      })
    },
    // 测试
    requestGetTimedComplain() {
      getCourseJoin(this.areaCode,this.areaLevel).then(res=>{
        this.todayPvNumber=res.data
      })
    },
    //课程分类占比
    requestGetDaTjInformation() {
      getCourseTypeCount(this.areaLevel,this.areaCode).then(res=>{
        this.courseTypeCount=res.data
      })
    },
    //课程分类占比
    requestGetDaTjInformationNew() {
      getCourseTypeCountNew(this.areaLevel,this.areaCode).then(res=>{
        this.courseTypeCount=res.data
      })
    },
    // 测试
    requestGetComplaintOverview() {
      var param = this.setParamNoDate()
      this.complaintOverview = {
        "ypjamount": this._mathRandom1000(),
        "ypjmy": this._mathRandom1000(),
        "amounts": this._mathRandom1000(),
        "ybjlastweek": this._mathRandom1000(),
        "ybjtoday": this._mathRandom1000(),
        "ybjamounts": this._mathRandom1000(),
        "ybjyesterday": this._mathRandom1000()
      }
    },
    // 测试
    requestGetPageview() {
      var param = this.setParamNoDate()
      this.pageview = {
        "total": this._mathRandom1000(),
        "lastweekpv": this._mathRandom1000(),
        "beforeyesterdaypv": this._mathRandom1000(),
        "yesterdaypv": this._mathRandom1000()
      }
    },
    // 测试
    requestGetTotalPublish() {
      var param = this.setParamNoDate()
      this.totalPublish = this._mathRandom1000()
    },
    // 测试
    requestGetTotalItems() {
      var param = this.setParamNoDate()
      this.totalItems = this._mathRandom1000()
    },
    // 测试
    requestGetThirdPlatform() {
      this.thirdPlatform = this._mathRandom1000()
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep#largescreen {
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  padding: 0;
  background-image: url("../assets/images/largescreen/home-bg1.png");

  .el-col {
    height: 100%;
    position: relative;

    .date-range {
      position: absolute;
      right: 5px;
      z-index: 9;

      .el-date-editor {
        background: transparent;
        border-color: rgb(0, 186, 255);
        width: 250px;

        .el-icon-date {
          color: #fff;
        }

        .el-range-separator {
          color: #fff;
        }

        .el-range__close-icon {
          color: #fff;
        }

        .el-range-input {
          background: transparent;
          color: #fff;
        }
      }
    }

    .map-item-amounts {
      position: absolute;
      z-index: 9;
      right: 15px;
      // top: 30px;
      color: #fff;
      font-size: 16px;
      padding-top: 10px;
      color: #24CFF4;
    }
  }

  .el-carousel {
    height: calc(33.3%);
    //margin-bottom: 10px;
    .el-carousel__container {
      height: 100%;
    }

    .el-carousel__indicator--horizontal {
      padding: 3px;
    }
  }
}
</style>

