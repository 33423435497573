import request from "@/utils/request";  //导入封装请求的js文件

//获取详细信息
export function getSchoolCount(areaLevel,areaCode) {
	return request({
		url: '/LargeScreen/GetSchoolCount?areaLevel='+areaLevel+"&areaCode="+areaCode,
		method: 'get'
	})
}

//获取当前地区级别名称
export function getCurrentLevelName() {
	return request({
		url: '/LargeScreen/GetAreaName',
		method: 'get'
	})
}

//获取当前地区级别名称
export function getCurrCountInfo(areaLevel,areaCode) {
	return request({
		url: '/LargeScreen/GetCurrSchoolInfo?areaLevel='+areaLevel+"&areaCode="+areaCode,
		method: 'get'
	})
}

//获取已开通年级饼图
export function getClassCount(areaLevel,areaCode) {
	return request({
		url: '/LargeScreen/GetGradeList?areaLevel='+areaLevel+"&areaCode="+areaCode,
		method: 'get'
	})
}

//获取已开通年级饼图(新)
export function getClassCountNew(areaLevel,areaCode) {
	return request({
		url: '/LargeScreen/GetGradeListNew?areaLevel='+areaLevel+"&areaCode="+areaCode,
		method: 'get'
	})
}

//获取课程分类占比
export function getCourseTypeCount(areaLevel,areaCode) {
	return request({
		url: '/LargeScreen/GetCourseTypeCount?areaLevel='+areaLevel+"&areaCode="+areaCode,
		method: 'get'
	})
}

//获取课程分类占比(新)
export function getCourseTypeCountNew(areaLevel,areaCode) {
	return request({
		url: '/LargeScreen/GetCourseTypeCountNew?areaLevel='+areaLevel+"&areaCode="+areaCode,
		method: 'get'
	})
}


//获取课程分类占比
export function getCourseJoin(areaCode,areaLevel) {
	return request({
		url: '/LargeScreen/GetCurrSchoolInfo?areaLevel='+areaLevel+"&areaCode="+areaCode,
		method: 'get'
	})
}

//获取各学校数据
export function getCurrSchoolInfo(areaCode,areaLevel) {
	return request({
		url: '/LargeScreen/GetCurrSchoolInfo?areaLevel='+areaLevel+"&areaCode="+areaCode,
		method: 'get'
	})
}