<template>
  <div id="chart-board-box">
  <div class="chart-title">
    <img class="charts-icon" :src="require('@/assets/images/largescreen/charts-icon.png')" alt="">
    <dv-decoration-1/>
    <span style="font-size: 12px;">{{areaName === 'china' ? '全国' : areaName}}</span>
  </div>

  <div class='' style='height: 100%;width:100%' @mouseout='mouseout' @mouseover='mouseover'>
    <el-table
    :data="tableData"
    stripe
    class="swiper-page-table"
    ref="table"
    height="90%"
  >
          <el-table-column
              type="index"
              label="序号"
               >
            </el-table-column>
            <el-table-column
                v-for="(item, index) in tableLabel"
                :key="index" 
                :prop="item.prop" 
                :width="item.width" 
                :label="item.label">
              </el-table-column>
  </el-table>
  </div>
</div>
</template>

<script>

export default {
name: 'test',
data() {
  return {
    // 调节滚动速率
    timer: 15,
    tableData: [],
    status:true,
    tableLabel: [
      {label: '部署学校', width: '', prop: 'schoolName'},
      {label: '参与教师数', width: '', prop: 'teacherCount'},
      {label: '覆盖学生数', width: '', prop: 'studentCount'},
      {label: '累计课时', width: '', prop: 'chapterCount'}
    ]
  }
},
mounted() {
},
watch: {
  schoolInfoList: {
    handler(val) {
      this.initData()
    },
  },
  areaName:{
    handler(val){
      if (this.columName == '3') {
        this.tableLabel = [
            {label: '部署年级', width: '', prop: 'schoolName'},
            {label: '参与教师数', width: '', prop: 'teacherCount'},
            {label: '覆盖学生数', width: '', prop: 'studentCount'},
            {label: '累计课时', width: '', prop: 'chapterCount'}
          ]
      }
      this.initData()
    }
  }
},
computed: {
           optionHover() {
             return {
               step: 0.1, // 数值越大速度滚动越快
               limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
               hoverStop: true, // 是否开启鼠标悬停stop
               direction: 1, // 0向下 1向上 2向左 3向右
               openWatch: true, // 开启数据实时监控刷新dom
               singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
               singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
               waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
             };
           },
         },
props: {
  areaName: {
    type: String,
    default() {
      return ''
    }
  },
  schoolInfoList:{
    type:Array,
    default(){
      return []
    }
  },
    columName: {
      type: String,
      default() {
        return '1'
      }
    }
},  
methods:{
initData(){
    this.tableData= this.schoolInfoList
    this.mouseout()
},
mouseover() {
    clearInterval(this.timer)
  },
  mouseout() {
    this.autoScroll(false)
  },
  autoScroll(init) {
    this.$nextTick(() => {
      const t = 50
      const box = this.$el.querySelector('.el-table__body-wrapper')
      const content = this.$el.querySelector('.el-table__body')
      if (init) box.scrollTop = 0
      this.timer = setInterval(() => {
        this.rollStart(box, content)
      }, t)
    })
  },
  rollStart(box, content) {
    if (box.scrollTop >= (content.scrollHeight - box.offsetHeight)) {
      box.scrollTop = 0
    } else {
      box.scrollTop++
    }
  }
}
}
</script>

<style lang="less" scoped>

/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
background-color: transparent;
color: blanchedalmond;
  font-size: 12px;
}

::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
border-bottom: 0;
}


::v-deep.el-table--enable-row-transition .el-table__body td.el-table__cell {
background-color: transparent !important;
}
::v-deep.el-table td.el-table__cell div.cell {
background-color: transparent !important;
}
::v-deep .el-table {
background-color: transparent !important;
}

::v-deep .el-table__row {
background-color: transparent !important;
}
/* 去除底部白线 */
.el-table::before{
  background-color: transparent;
}

#chart-board-box {
// background: rgba(10, 57, 130, .6);
background: rgba(125,155,243,.2);
height: 33.4%;
color: #fff;

.chart-title {
  display: flex;
  color: #fff;
  font-weight: bolder;
  padding: 5px 10px;
  background: rgba(125, 155, 243, 0.2);
  position: relative;

  .charts-icon{
    width: 20px;
    height: 25px;
    position: absolute;
    left: 15px;
    top: 0;
  }

  .dv-decoration-1 {
    width: 50px;
    height: 18px;
  }
}

#chart-board {
  padding: 0 10px 10px 10px;
  height: calc(100% - 30px);

  .amounts-box{
    height: calc(50%);
    padding: 10px 0 10px 10px;

    .amounts-box-card{
      height: 100%;
      padding: 10px 0 10px 10px;
      background-image: url("../assets/images/largescreen/amounts-box.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .amounts-num{
        font-size: 12px;
        color: #05E7CF;
        font-weight: bolder;
        padding-left: 5px;
      }

      .amounts-unit{
        font-size: 12px;
        color: #05E7CF;
      }

      .amounts-day-box{
        span{
          padding-right: 3px;
        }
      }
    }
  }

  .amounts-total-box{
    display: flex;
    height: 100%;
    .amounts-total-bgline{
      width: 15px;
      height: 100%;
      background-image: url("../assets/images/largescreen/amounts-bgline.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .amounts-total-card{
      height: 100%;
      padding: 10px;
      flex-grow: 1;
      padding-left: 10%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .amounts-total{

        &>div{
          display: flex;
          align-items: center;
        }

        .amounts-total-icon{
          width: 15px;
          height: 15px;
        }
        .amounts-total-title{
          padding-left: 5px;
        }
        .amounts-total-num{
          padding-left: 20px;
          font-size: 12px;
          font-weight: bolder;
          color: #05E7CF;
        }
      }
    }
  }

  .triangleUp{
    color: red;
  }
  .triangleDown{
    color: green;
  }
}
}
.backround{
  width: 100%;
  height: 1000px;
  background-image: url("../assets/images/largescreen/amounts-box.png");
}
/deep/ .el-table, /deep/ .el-table__expanded-cell{
background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
background-color: transparent;
color:white
}
.seamless-warp{
  height: 200px;
  overflow: hidden;
}
/deep/.el-table .cell{
  text-align: center;
  font-size: 12px;
}
/deep/.toptitle{
  width: 100%;
  display: flex;
  background-color: gainsboro;
  margin-bottom: 10px;
padding-top: 10px;
font-size: 12px;
  // border:2px solid gainsboro;
  background-color: transparent;
  color:rgba(255, 255, 255, 0.418)
}
/deep/.el-table__body-wrapper::-webkit-scrollbar{
  width: 0;
}
.item{
  width: 20%;
  // border-right:2px solid gainsboro;
  text-align: center;
}
</style>