<template>
	<div id="chart-bar-area-publish-box">
		<div class="chart-title">
			<img class="charts-icon" :src="require('@/assets/images/largescreen/charts-icon.png')" alt="">
			<dv-decoration-1 />
			<span style="font-size: 12px;">已开设心理课程各年级占比</span>
		</div>
		<div id="chart-bar-area-publish3" />
	</div>
</template>


<script>
import resize from '@/utils/resize'
import leftColor from '../assets/leftColor'

export default {
	mixins: [resize],
	name: "HomeView",
	components: {},
	props: {
		gradeList: {
			type: Array,
			default() {
				return []
			}
		}
	},
	watch: {
		gradeList: {
			handler(val) {
				this.drawCharts()
			},
		}
	},
	data() {
		return {
			chartPie: null,
			opend: 0,
			noopen: 1,
			seriesdata: []
		}
	},
	methods: {
		drawPieChart() {
			// echarts.registerTheme('leftColor',leftColor)
			
			this.chartPie = this.$echarts.init(document.getElementById('chart-bar-area-publish3'));
			this.chartPie.setOption({
				tooltip: {
					trigger: 'item',
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [{
					name: '课程分类占比',
					type: 'pie',
					radius: '55%',
					center: ['50%', '60%'], //饼图的位置
					label: {            //饼图图形上的文本标签
						normal: {
							show: true,
							textStyle: {
								fontSize: 12,//文字的字体大小                  
							},
						}
					},
					data: this.seriesdata,
					itemStyle: {
						emphasis: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					},
					color: [
					"#2ec7c9",
        "#b6a2de",
        "#5ab1ef",
        "#ffb980",
        "#d87a80",
        "#8d98b3",
        "#e5cf0d",
        "#97b552",
        "#95706d",
        "#dc69aa",
        "#07a2a4",
        "#9a7fd1",
        "#588dd5",
        "#f5994e",
        "#c05050",
        "#59678c",
        "#c9ab00",
        "#7eb00a",
        "#6f5553",
        "#c14089"
					],
					itemStyle: {
						emphasis: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					},
					labelLine: {
						normal: {
							show: true,
							length: 8,
							length2: 6,
						}
					},
					animationType: 'scale',
					animationEasing: 'elasticOut',
				}]
			});

			window.addEventListener("resize", () => {
				if (this.chartPie) {
					this.chartPie.resize()
				}
			})
		},
		drawCharts() {
			this.seriesdata = []
			this.gradeList.forEach((item, index) => {
				this.seriesdata.push({
					value: item.gradeNum,
					name: item.gradeName
				})
			})
			this.drawPieChart()
		},
	}
};
</script>

<style lang="scss" scoped>
#chart-bar-area-publish-box {
	background: rgba(125, 155, 243, .2);
	height: 50%;

	.chart-title {
		display: flex;
		color: #fff;
		font-weight: bolder;
		padding: 5px 10px;
		background: rgba(125, 155, 243, .2);
		position: relative;

		.charts-icon {
			width: 20px;
			height: 25px;
			position: absolute;
			left: 15px;
			top: 0;
		}

		.dv-decoration-1 {
			width: 50px;
			height: 18px;
		}
	}

	#chart-bar-area-publish3 {
		height: calc(100% - 30px);
	}
}
</style>